import {User} from '@hconnect/apiclient/src/response/User'
import {useQuery} from 'react-query'

import {getRequestPathData} from './utils'

type QueryKey = [string, {userId: string}]

const userFetcher = async ({queryKey}) => {
  const [base, {userId}] = queryKey as QueryKey

  if (!userId) return

  return getRequestPathData<User>(`/${base}/${userId}`)
}

export const useUser = (userId: string | null) =>
  useQuery(['users', {userId}], userFetcher, {
    refetchOnWindowFocus: true,
    retry: false
  })
